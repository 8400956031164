<template>
  <div>
    <my-nav-bar
      title="联盟商品"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-fixed-container">
      <div class="list">
        <div v-for="(orderGoods, index) in orderGoodsList" :key="index" class="goods-container">
          <van-cell :title="orderGoods.client.name" />
          <div class="goods">
            <div v-if="orderGoods.is_trans" class="finished-logo">
              <van-image width="1.78667rem" height="1.6rem" :src="require('@/assets/images/yichuku.png')" />
            </div>
            <div>
              <van-image :src="orderGoods.image" class="goods-image" />
            </div>
            <div class="goods-info">
              <div class="goods-name">{{ orderGoods.name }}</div>
              <div class="goods-size">规格：1 * {{ orderGoods.size }}</div>
              <div class="goods-quantity">
                <span>数量: </span>
                <span v-if="orderGoods.large_volume">
                  {{ orderGoods.large_volume }}{{ orderGoods.large_unit }}
                </span>
                <span v-if="orderGoods.least_volume">
                  {{ orderGoods.least_volume }}{{ orderGoods.least_unit }}
                </span>
              </div>
            </div>
          </div>
          <van-button block square type="primary" size="small" @click="handleConfirm(orderGoods)">确认出库</van-button>
        </div>
      </div>
    </div>
    <div class="fixed-bottom-btn">
      <van-button :disabled="checkNotFinished()" block square @click="handleFinished">完成出库</van-button>
    </div>
  </div>
</template>

<script>
import { getBusinessOrderDetail, businessOrderGoodsConfirm, finishBusinessOrder } from '@/api/trans_out'
import myNavBar from '@/components/my-nav-bar'
export default {
  components: { myNavBar },
  data() {
    return {
      orderGoodsList: []
    }
  },
  created() {
    getBusinessOrderDetail().then(res => {
      this.orderGoodsList = res.data
    })
  },
  methods: {
    checkNotFinished() {
      const notTranses = this.orderGoodsList.filter(og => {
        return og.is_trans === 0
      })
      return notTranses.length > 0
    },
    handleConfirm(orderGoods) {
      this.confirm('确定商品已出库吗？', '提示').then(() => {
        this.beginLoad()
        const data = {
          order_goods_id: orderGoods.id
        }
        businessOrderGoodsConfirm(data).then(res => {
          this.endLoad()
          const index = this.orderGoodsList.findIndex(og => og.id === orderGoods.id)
          if (index !== -1) {
            this.orderGoodsList.splice(index, 1)
            this.orderGoodsList.push(orderGoods)
            orderGoods.is_trans = 1
          }
        })
      })
    },
    handleFinished() {
      this.confirm('确定已出库完成了吗？', '提示').then(() => {
        this.beginLoad()
        finishBusinessOrder().then(() => {
          this.endLoad()
          this.$router.back()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .list {
    padding: 10px;
  }
  .goods-container {
    margin-bottom: 10px;
  }
  .goods-container:last-child {
    margin-bottom: 0;
  }
  .goods {
    position: relative;
    background-color: #fff;
    display: flex;
    padding: 10px;
  }
  .goods-info {
    margin-left: 10px;
  }
  .goods-image {
    width: 80px;
    height: 80px;
  }
  .goods-name {
    font-size: 15px;
  }
  .goods-size, .goods-quantity {
    font-size: 13px;
    color: #989898;
    margin-top: 3px;
  }
  .finished-logo {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
</style>
