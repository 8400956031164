var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "联盟商品",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-fixed-container"
  }, [_c('div', {
    staticClass: "list"
  }, _vm._l(_vm.orderGoodsList, function (orderGoods, index) {
    return _c('div', {
      key: index,
      staticClass: "goods-container"
    }, [_c('van-cell', {
      attrs: {
        "title": orderGoods.client.name
      }
    }), _c('div', {
      staticClass: "goods"
    }, [orderGoods.is_trans ? _c('div', {
      staticClass: "finished-logo"
    }, [_c('van-image', {
      attrs: {
        "width": "1.78667rem",
        "height": "1.6rem",
        "src": require('@/assets/images/yichuku.png')
      }
    })], 1) : _vm._e(), _c('div', [_c('van-image', {
      staticClass: "goods-image",
      attrs: {
        "src": orderGoods.image
      }
    })], 1), _c('div', {
      staticClass: "goods-info"
    }, [_c('div', {
      staticClass: "goods-name"
    }, [_vm._v(_vm._s(orderGoods.name))]), _c('div', {
      staticClass: "goods-size"
    }, [_vm._v("规格：1 * " + _vm._s(orderGoods.size))]), _c('div', {
      staticClass: "goods-quantity"
    }, [_c('span', [_vm._v("数量: ")]), orderGoods.large_volume ? _c('span', [_vm._v(" " + _vm._s(orderGoods.large_volume) + _vm._s(orderGoods.large_unit) + " ")]) : _vm._e(), orderGoods.least_volume ? _c('span', [_vm._v(" " + _vm._s(orderGoods.least_volume) + _vm._s(orderGoods.least_unit) + " ")]) : _vm._e()])])]), _c('van-button', {
      attrs: {
        "block": "",
        "square": "",
        "type": "primary",
        "size": "small"
      },
      on: {
        "click": function click($event) {
          return _vm.handleConfirm(orderGoods);
        }
      }
    }, [_vm._v("确认出库")])], 1);
  }), 0)]), _c('div', {
    staticClass: "fixed-bottom-btn"
  }, [_c('van-button', {
    attrs: {
      "disabled": _vm.checkNotFinished(),
      "block": "",
      "square": ""
    },
    on: {
      "click": _vm.handleFinished
    }
  }, [_vm._v("完成出库")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }